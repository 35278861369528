import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "withoutSeconds"
  ]

  connect() {
    this.withoutSecondsTargets.forEach(
      e => {
        utils.replaceContentWithTimeWithoutSecondsFromMs(e, e.dataset.localTimeMs)
      }
    )
  }
}
