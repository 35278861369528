import { Controller } from "stimulus"
//import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  connect() {
    window.setTimeout(e => this.bodyScrolled(null), 100)
    window.addEventListener("scroll", ev => this.bodyScrolled(ev))
    window.addEventListener("resize", ev => this.bodyScrolled(ev))
    document.addEventListener("DOMSubtreeModified", ev => this.bodyScrolled(ev))
    document.addEventListener("turbolinks:load", ev => setTimeout(this.pageLoad(ev), 120))

    window.setTimeout(ev => this.hideSpinner(ev), 120)
    document.addEventListener("ajax:beforeSend", ev => this.showSpinner(ev))
  }

  bodyScrolled(ev) {
  }

  pageLoad(ev) {
  }

  showSpinner(ev) {
    Turbolinks.controller.adapter.progressBar.setValue(0)
    Turbolinks.controller.adapter.progressBar.show()
  }

  hideSpinner(ev) {
    if (document.getElementsByClassName("turbolinks-progress-bar").length > 0) {
      Turbolinks.controller.adapter.progressBar.setValue(1)
      Turbolinks.controller.adapter.progressBar.hide()
    }
  }
}
