import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"
// import * as inputs from "src/js/input_helper"

export default class extends Controller {
  static targets = [
    "loadingSpinner",
    "generateDirectDebit",
    "cancelButton"
  ]

  generateDirectDebitButton() {
    utils.hide(this.generateDirectDebitTarget)
    utils.hide(this.cancelButtonTarget)
    utils.show(this.loadingSpinnerTarget)
  }
}
