import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "loadingSpinner",
    "submitRequest",
    "cancelButton"
  ]

  submitRequestButton() {
    utils.hide(this.submitRequestTarget)
    utils.hide(this.cancelButtonTarget)
    utils.show(this.loadingSpinnerTarget)
  }
}
