import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "agreementAlert",
    "agreementAccept"
  ]

  agreementViewed() {
    utils.hide(this.agreementAlertTarget)
    utils.enable(this.agreementAcceptTarget)
  }

}
