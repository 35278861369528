import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "loadingSpinner",
    "generateDocument"
  ]

  generateDocumentButton() {
    utils.hide(this.generateStatementTarget)
    utils.show(this.loadingSpinnerTarget)
  }
}
